import 'bootstrap/dist/css/bootstrap.min.css'
import initializeStore from '/stores/stores'
import React, { useEffect } from 'react'
import { Provider, inject, observer } from 'mobx-react'
import App from 'next/app'
import Cookies from 'cookies'
import ErrorPage from 'next/error'
import { QueryClient, QueryClientProvider } from 'react-query'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import '/styles/App.scss'
import '/styles/sass/style.scss'
import DefaultLayout from '/components/layouts/DefaultLayout'
import AuthWall from '/components/AuthWall'
import { logInit } from '/components/logger'
import { PAGEURL } from '/constants'
import { isServer } from '/components/utils'
import { pageView } from '/components/analytics/utils'
import { SearchProvider } from '/components/context/search'

import 'react-toastify/dist/ReactToastify.css'

logInit()

const Chalice = inject('store')(
  observer(
    ({ store, Component, pageProps, simpleAuth, siteData, pathname }) => {
      const Layout = Component.getLayout
        ? Component.getLayout(pageProps)
        : Component.Layout || DefaultLayout

      useEffect(() => {
        async function load() {
          await store.loadEverything()
        }
        load()
      }, [store])

      // No site data, so domain wasn't found, then we return a 404
      if (!siteData) {
        return <ErrorPage statusCode={404} />
      }
      // The user is trying to signup, but this site doesn't allow it
      if (!siteData.allow_signup) {
        if (
          pathname === PAGEURL.SIGNUP_ ||
          pathname === PAGEURL.SIGNUP_REGISTER_ ||
          pathname === PAGEURL.SIGNUP_SUCCESS ||
          pathname === PAGEURL.SIGNUP_ADD_ONS
        ) {
          return <ErrorPage statusCode={404} />
        }
      }

      const queryClient = new QueryClient()

      if (siteData.is_protected && !simpleAuth) {
        return (
          <div>
            <QueryClientProvider client={queryClient}>
              <Layout siteData={siteData}>
                <AuthWall />
              </Layout>
            </QueryClientProvider>
          </div>
        )
      }

      return (
        <div>
          <QueryClientProvider client={queryClient}>
            <DndProvider backend={HTML5Backend}>
              <SearchProvider>
                <Layout siteData={siteData}>
                  <Component {...pageProps} siteData={siteData} />
                </Layout>
              </SearchProvider>
            </DndProvider>
          </QueryClientProvider>
        </div>
      )
    }
  )
)

const ChaliceNetwork = (props) => {
  const store = isServer()
    ? props.initialStoreState
    : initializeStore(props.initialStoreState)

  if (props.siteData && props.siteData !== null) {
    store.siteData.replace(props.siteData)
  }

  return (
    <Provider store={store}>
      <Chalice
        Component={props.Component}
        pageProps={props.pageProps}
        siteData={props.siteData}
        host={props.host}
        pathname={props.pathname}
        simpleAuth={props.simpleAuth}
      />
    </Provider>
  )
}

ChaliceNetwork.getInitialProps = async (appContext) => {
  let host = null
  // let cookie = null
  let simpleAuth = false
  const { req, res, pathname, query } = appContext.ctx
  if (req) {
    const { headers, url, method } = req
    host = process.env.CUSTOM_HOST =
      headers['x-forwarded-server'] || headers.host || 'www.chalicenetwork.com'
    // cookie = (req.headers.cookie || null)
    const cookies = new Cookies(req, res)
    simpleAuth = cookies.get('simple-authentication')
    pageView({ headers, url, query, method }, host)
  } else {
    // If they have gotten this far they have already authenticated
    simpleAuth = true
  }

  const store = initializeStore()
  appContext.ctx.store = store
  const data = await store.fetchSiteData(host)
  if (data.statusCode === 302 && data.response.location) {
    const newLocation =
      data.response.location + req.url + (req.search ? `?${req.search}` : '')
    res.writeHead(302, { Location: newLocation })
    res.end()
    return
  }
  // const advisor = await store.fetchAdvisor(host, cookie)
  const siteData = data.response
  const appProps = await App.getInitialProps(appContext)
  appContext.ctx.siteData = siteData

  return {
    ...appProps,
    initialStoreState: store,
    siteData,
    host,
    pathname,
    simpleAuth
    // advisor,
  }
}

export default ChaliceNetwork
